import React, { Component, useState, useEffect } from 'react';
import { motion } from 'framer-motion'
import './Contact.scss';

export default function Contact(props) {
    const { onClose } = props;
    return (
        <div className={'Contact'}>
            <div className={'Contact-backdrop'} onClick={onClose}></div>
            <div className={'Contact-modal'}>
                <h1 onClick={onClose}>✌️</h1>
                <div className={'Contact-modal-methods'}>
                    <a href="https://instagram.com/saltvehicleworks" target={'_blank'}>Instagram</a>
                    <a href="https://www.linkedin.com/in/saltvehicleworks/" target={'_blank'}>LinkedIn</a>
                    <a href="mailto:hello@saltvehicleworks.com">Email</a>
                </div>
            </div>

        </div>
    )
}