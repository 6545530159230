import React, { Component, useState, useEffect } from 'react';
import { motion } from 'framer-motion'
import './App.scss';
import Contact from './components/Contact/Contact';

const logo = require('./assets/logo-salt.svg');

const SUBTITLES = [
	'Custom built classics',
	'Vintage restoration',
	'Bespoke interiors',
	'Sunday rides',
	'Every day drivers',
	'Vintage Porsche',
	'Land Rover Overland',
	'Classic Americana'
]

const VARIANTS = {
	open: { opacity: .5, x: 0 },
	closed: { opacity: 0, x: "-10px" },
}

const TIMER = 3000;


export default function App() {

	const [count, setCount] = useState(0);
	const [animate, setAnimate] = useState('open');
	const [contact, setContact] = useState(false);

	return (
		<div className={'App'}>
			<div className={'App-intro'}>
				<div id={'logo'}>
					<img src={logo} alt="Salt Vehicle Design" />
				</div>
				<div className={'text'}>
					<motion.h2
						animate={animate}
						variants={VARIANTS}
					>Salt is an automotive studio based on the love of vintage cars, motorcycles, and the lure of dust trails over salt flats. We find, design, and restore classic project vehicles.</motion.h2>
				</div>
				<footer>
					<div onClick={() => {
						window.location.href = '/#inventory'
						// window.scrollTo({
						// 	top: window.innerHeight,
						// 	behavior: 'smooth'
						// });
					}}>
						Salt  <br />
						Inventory
					</div>
					<div onClick={() => {
						window.location.href = '/#project'
						// window.scrollTo({
						// 	top: window.innerHeight * 2,
						// 	behavior: 'smooth'
						// });
					}}>

						Start your <br />
						Project Build
					</div>
					{/* <div>
						Say Hello<br />
						@<span className={'link'} onClick={() => { setContact(true) }}>saltvehicledesign</span>
					</div> */}
				</footer>
			</div>

			<div className={'App-intro'}>

				<div className={'text'}>
					<a name="inventory" />
					<h1>Current Project Inventory</h1>
					<div className={'inventory'}>
						<h3>
							1956 AJS 500cc Twin<br />
							1963 Land Rover Series II<br />
							1967 Land Rover Series IIa Pickup<br />
							1968 Porsche 912 Targa EV<br />
							1969 Porsche 912 Coupe<br />
						</h3>
						<h3>
							1965 Ford Mustang Coupe<br />
							1969 Ford F-100 Custom Cab<br />
							1978 BMW R/100<br />
							1984 Land Rover Defender 90<br />
							1991 Mercedes 300 G-Classe <br />
						</h3>
					</div>
				</div>


				<div className={'seeking columns'}>
					<div className={'text'}>
						<h1>Seeking<br />
							Inventory</h1>
						<p>We're currently on the hunt for an early-year Ford Bronco and mid-70s FJ40.</p>
					</div>
					<div className={'text'}>
						<h1>Currently<br />
							Hiring</h1>
						<p>Salt is looking for part-time mechanics and upholsterers specializing in 60s-80s era classic cars.</p>
					</div>
				</div>



			</div>

			<div className={'App-intro'}>

				<div className={'text'}>
					<a name="project" />
					<h1>Have a project or<br />want to start one? </h1>
					<h3 className={'link'} onClick={() => { setContact(true) }}>Reach out now</h3>
				</div>

				<div className={'seeking'}>
					<div className={'text'}>
						<h1>Follow us</h1>
						<p className={'link'} onClick={() => { setContact(true) }}>@saltvehicleworks</p>
					</div>

				</div>

			</div>

			{
				contact && <Contact onClose={() => {
					setContact(false)
				}} />
			}
		</div>
	);

}


